import useApiQuery from '@/hooks/useApiQuery';
import { URANUS_API_URL } from 'buildConfig/processEnv';

export interface Drivers {
    driver: 'Calendar';
    impact: '-5.1M';
    impact_level: 'High';
}

export const useDriverImpact = ({ disabled = false } = {}) => {
    const url = new URL(
        `${URANUS_API_URL}/promo-management/api/promo-management/insights/driver_impact`,
    );

    return useApiQuery<Drivers[]>({ url: url.toString(), disabled });
};
