import { UlyssesContextProvider } from '@/components/ulysses/context';
import ResponsiveLayoutUlysses from '@/components/ulysses/ResponsiveLayoutUlysses';
import { SegmentsAnalysisPage } from '@/components/ulysses/SegmentsAnalysisPage';

const SegmentsAnalysis = () => (
    <ResponsiveLayoutUlysses title="Segment Analysis">
        <UlyssesContextProvider>
            <SegmentsAnalysisPage />
        </UlyssesContextProvider>
    </ResponsiveLayoutUlysses>
);

export default SegmentsAnalysis;
