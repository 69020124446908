import { SelectItem } from 'andromeda/types/select/common';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import StyledTooltip from '@/components/utils/styledTooltip';
import { CellSignalFull, CellSignalMedium, CellSignalNone } from 'phosphor-react';
import { driverBreakdown } from './driverBreakdown';
import { useDriverImpact } from '../useDriverImpact';

const cellSignalLevel = level => {
    switch (level) {
        case 'Low':
            return <CellSignalNone weight="fill" size={16} />;
        case 'Medium':
            return <CellSignalMedium weight="fill" size={16} />;
        case 'High':
            return <CellSignalFull weight="fill" size={16} />;
        default:
            return <CellSignalNone weight="fill" size={16} />;
    }
};

const ToggleButton = React.forwardRef(
    (
        {
            onClick,
            children,
            classname,
            isActive,
            displayVal,
            driverImpact,
        }: {
            children: string;
            onClick: () => void;
            classname?: string;
            isActive: boolean;
            displayVal?: string;
            driverImpact?: string;
        },
        ref: React.Ref<HTMLButtonElement>,
    ) => (
        <div className="relative flex">
            <button
                id={`toggle${children}`}
                ref={ref}
                type="button"
                onClick={onClick}
                className={classNames(
                    'relative z-10 px-[13px] py-2 whitespace-nowrap text-xs flex items-center justify-center rounded-full gap-2',
                    classname,
                    {
                        'text-secondary': isActive,
                        'text-columbia-blue-v2-400 hover:text-secondary': !isActive,
                    },
                )}
            >
                {children}
                {displayVal !== 'Sales Construct' && cellSignalLevel(driverImpact)}
            </button>
            {isActive ? (
                <motion.div
                    className="absolute z-0 size-full rounded-full bg-primary "
                    layoutId="toggle"
                />
            ) : null}
        </div>
    ),
);

function Interests({
    data,
    defaultOption,
    onChange,
    value,
}: {
    data: (SelectItem | string)[];
    onChange: (value: string) => void;
    defaultOption: string;
    value: string;
}) {
    const defaultValue = data.filter(item =>
        typeof item !== 'string' ? item.value === defaultOption : item === defaultOption,
    )?.[0];

    const { data: driverData } = useDriverImpact();

    const getButtonLabel = displayVal =>
        `${displayVal} consists of metrics: ${Object.keys(driverBreakdown[displayVal])}`;

    return (
        <div className="relative mr-5 flex h-8 flex-nowrap items-center gap-2 overflow-hidden lg:mr-0">
            <ToggleButton
                isActive={!value || value === defaultOption}
                onClick={() => onChange(defaultOption)}
                displayVal={defaultOption}
                driverImpact="low"
            >
                {defaultValue?.['label'] ?? defaultOption}
            </ToggleButton>
            <div className="h-8 w-px shrink-0 bg-gray-light" />
            <div className="mr-4 flex h-8 grow gap-2 overflow-x-auto">
                {data.map(option => {
                    const val = typeof option !== 'string' ? option?.value : option;
                    const displayVal = typeof option !== 'string' ? option?.label : option;
                    const driverImpact = driverData?.find(item => item.driver === displayVal);
                    if (defaultOption === val) return null;
                    return (
                        <StyledTooltip label={getButtonLabel(displayVal)} key={val}>
                            <ToggleButton
                                isActive={value === val}
                                onClick={() => onChange(val)}
                                displayVal={displayVal}
                                driverImpact={driverImpact?.impact_level}
                            >
                                {displayVal}
                            </ToggleButton>
                        </StyledTooltip>
                    );
                })}
            </div>
            <div className="pointer-events-none absolute right-0 h-8 w-7 bg-gradient-to-r from-transparent to-white lg:hidden" />
        </div>
    );
}

export default Interests;
