import useApiQuery from '@/hooks/useApiQuery';
import { URANUS_API_URL } from 'buildConfig/processEnv';

export interface SegmentData {
    name: string;
    title: string;
    subtitle: string;
}
export interface Segment {
    title: string;
    data: SegmentData[][];
}

export const useGetSegmentAnalysis = (
    granularity: string,
    tradeFormat?: string,
    maxDate?: string,
    segment?: string,
) => {
    const url = new URL(
        `${URANUS_API_URL}/promo-management/api/promo-management/insights/segment_analysis_table?granularity=${granularity}&timescale=yoy`,
    );

    if (segment) {
        url.searchParams.set('driver', segment);
    }

    if (tradeFormat) {
        url.searchParams.set('trade_format', tradeFormat);
    }

    if (maxDate) {
        url.searchParams.set('max_date', maxDate);
    }

    return useApiQuery<Segment>({ url: url.toString() });
};
