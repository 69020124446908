import { useEffect, useState } from 'react';
import { Briefcase, Graph, MagnifyingGlass, Tag } from '@phosphor-icons/react';
import { Barcode } from 'phosphor-react';
import { Input } from '@mantine/core';

import StyledTab from 'andromeda/tab/tabComponent';
import { TabValue } from 'andromeda/tab/tabWrapper';
import StyledTable from 'andromeda/styledTable';
import useRouterQuery from '@/hooks/useRouterQuery';
import Select from 'andromeda/selects/select';
import { useGetSegmentAnalysis } from '../useSegment';
import Interests from './Interests';
import useSegmentsTable, { TabsType } from './useSegmentsTable';
import { Header } from '../Header';

enum Tabs {
    BRAND = 'brand',
    CLUSTER = 'cluster_name',
    SUPERVISOR = 'supervisor',
}

enum Segments {
    SALES_CONSTRUCT = 'Sales Construct',
    CALENDAR = 'Calendar',
    WEATHER = 'Weather',
    VISITS = 'Visits',
    STORE_PERFORMANCE = 'Store Performance',
    COMPETITIVE_HEAT = 'Competitive Heat',
    // MARKET_GROWTH = 'Market Growth',
    INNOVATION_SUCCESS = 'Innovation Success',
    PROMOTIONS = 'Promotions',
    PRICING = 'Pricing',
    OOS = 'OOS',
}

// TODO: use api - `insights/granularities`
const granularityOptions: TabValue<TabsType> = [
    {
        label: 'Brands',
        value: 'brand',
        component: <div />,
        icon: <Barcode size={16} />,
    },
    {
        label: 'Clusters',
        value: 'cluster_name',
        component: <div />,
        icon: <Tag size={16} />,
    },
    {
        label: 'Supervisors',
        value: 'supervisor',
        component: <div />,
        icon: <Briefcase size={16} />,
    },
];

const tradeFormatOptions = [
    {
        label: 'GT',
        value: 'GT',
    },
];

const maxDateOptions = [
    {
        label: 'August 2024',
        value: '2024-08-31',
    },
];

export const SegmentsAnalysisPage = () => {
    const { handleURLParamsChange, router } = useRouterQuery<
        'tab' | 'segment' | 'row' | 'tradeFormat' | 'maxDate'
    >();
    const [selectedTab, setSelectedTab] = useState<TabsType>(granularityOptions[0]?.value);

    const [searchedBrand, setSearchedBrand] = useState('');
    const [selectedSegment, setSelectedSegment] = useState('Sales Construct');
    const [selectedRow, setSelectedRow] = useState<Record<string, string>>();

    const [selectedTradeFormat, setSelectedTradeFormat] = useState<string>('GT');
    const [selectedMaxDate, setSelectedMaxDate] = useState('2024-08-31');

    const { data: segmentTable, isLoading } = useGetSegmentAnalysis(
        selectedTab,
        selectedTradeFormat,
        selectedMaxDate,
        selectedSegment,
    );

    const { body, headerJSX } = useSegmentsTable({
        segmentTable,
        selectedTab,
        searchedBrand,
        selectedRow,
        selectedSegment,
    });

    useEffect(() => {
        if (!router.isReady) return;
        const { tab, segment, row, tradeFormat, maxDate } = router.query;

        if (typeof tab === 'string') setSelectedTab(tab as TabsType);
        if (typeof segment === 'string') setSelectedSegment(segment);
        if (typeof row === 'string') setSelectedRow(JSON.parse(row));
        if (typeof tradeFormat === 'string') setSelectedTradeFormat(tradeFormat);
        if (typeof maxDate === 'string') setSelectedMaxDate(maxDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const handleTabChange = (value: TabsType) => {
        // setSelectedSegment(null);
        setSelectedRow(undefined);
        setSelectedTab(value);
        handleURLParamsChange({
            key: 'tab',
            value,
            extraCleaning: ['row'],
        });
    };
    const handleSegmentChange = (value: string) => {
        setSelectedRow(undefined);
        setSelectedSegment(value);
        handleURLParamsChange({
            key: 'segment',
            value,
            extraCleaning: ['row'],
        });
    };

    return (
        <div className="ml-3 flex flex-col pb-12 lg:ml-5 lg:pb-0">
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between lg:pr-4">
                <Header pageName="Segments" message="" icon={Graph} />
            </div>
            <div className="mt-10">
                <div className="flex items-center justify-between">
                    <StyledTab
                        values={granularityOptions}
                        onChange={handleTabChange}
                        defaultTab={granularityOptions[0].value}
                    />
                    <div className="mr-8 flex items-center gap-4">
                        <Select
                            cleanDesign
                            data={tradeFormatOptions}
                            onChange={e => {
                                setSelectedTradeFormat(e);
                                handleURLParamsChange({ key: 'tradeFormat', value: e });
                            }}
                            placeholder="Placeholder"
                            value={selectedTradeFormat}
                            width={130}
                        />
                        <Select
                            cleanDesign
                            data={maxDateOptions}
                            onChange={e => {
                                setSelectedMaxDate(e);
                                handleURLParamsChange({ key: 'maxDate', value: e });
                            }}
                            placeholder="Placeholder"
                            value={selectedMaxDate}
                            width={130}
                        />
                    </div>
                </div>
                <div className="my-4 lg:hidden">
                    <Input
                        className="my-4 h-8 w-[262px] min-w-[262px] rounded-lg !border-0 bg-primary text-base"
                        classNames={{
                            input: '!border-0 !min-h-8 !h-8',
                            section: '!h-8',
                        }}
                        leftSection={<MagnifyingGlass />}
                        placeholder="Search"
                        value={searchedBrand}
                        onChange={e => setSearchedBrand(e.currentTarget.value)}
                    />
                </div>
                <div className="my-5 mr-5 flex w-full items-center justify-between">
                    <Interests
                        data={Object.values(Segments)}
                        onChange={handleSegmentChange}
                        defaultOption={Segments.SALES_CONSTRUCT}
                        value={selectedSegment}
                    />
                    <div className="hidden self-end lg:block">
                        <Input
                            className="mr-5 h-8 w-[262px] min-w-[262px] rounded-lg !border-0 bg-primary text-base"
                            classNames={{
                                input: '!border-0 !min-h-8 !h-8',
                                section: '!h-8',
                            }}
                            leftSection={<MagnifyingGlass />}
                            placeholder="Search"
                            value={searchedBrand}
                            onChange={e => setSearchedBrand(e.currentTarget.value)}
                        />
                    </div>
                </div>
                <div className="mr-5">
                    <StyledTable
                        headers={headerJSX ?? []}
                        body={body}
                        withRowBorders={false}
                        loading={isLoading}
                        minHeight={520}
                        maxHeight={520}
                    />
                </div>
            </div>
        </div>
    );
};
