import { formatNumber } from '@/utils/numberFormatHelpers';
import StyledTable from 'andromeda/styledTable';
import TableHeaderCell from 'andromeda/tableHeaderCell';
import { handleCellClickType, SortingType } from 'andromeda/tableHeaderCellCarret';
import classNames from 'classnames';
import { CaretDown, CaretUp } from 'phosphor-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Text } from 'andromeda';
import StyledTooltip from '@/components/utils/styledTooltip';
import { Segment } from '../useSegment';
import { driverBreakdown } from './driverBreakdown';

const headerIndexMap = {
    brand: 'Brands',
    cluster_name: 'Clusters',
    supervisor: 'Supervisors',
};
export type TabsType = 'brand' | 'cluster_name' | 'supervisor';
type StringWithIcon = JSX.Element | string;

const formatSubtitle = (subtitle: string, title: string): string => {
    if (!subtitle) {
        return '';
    }

    const shouldFormat = title?.includes('฿');

    if (shouldFormat) {
        return subtitle
            .replace(/-/g, '')
            .split(' ')
            .map((word, idx) => (idx === 0 ? formatNumber(word, '0.0a') : word))
            .join(' ');
    }

    return subtitle.replace(/-/g, '');
};

function modifyString(data: string): StringWithIcon {
    if (data.includes('limited data') || data.includes('0.0%') || data.includes('0 days')) {
        return <i className="text-columbia-blue-darker">{data}</i>;
    }
    if (data.includes('-')) {
        return (
            <div className="-ml-5 flex items-center whitespace-nowrap text-coral-dark">
                <CaretDown size={16} style={{ marginRight: '4px' }} />
                {formatSubtitle(data, '')}
            </div>
        );
    }
    return (
        <div className="-ml-5 flex items-center whitespace-nowrap text-emerald-dark">
            <CaretUp size={16} style={{ marginRight: '4px' }} />
            {formatSubtitle(data, '')}
        </div>
    );
}
const getSub = (name: string) => `sub${name}`;
const getColumnSpan = (item: { name: string; title: string; subtitle: string; i?: number }) =>
    `${item.name}_${item.title}_${item.subtitle}${item.i || ''}`;

function useSegmentsTable({
    searchedBrand: searchedIndex,
    segmentTable,
    selectedTab,
    selectedRow,
    selectedSegment,
}: {
    segmentTable: Segment;
    selectedTab: TabsType;
    searchedBrand: string;
    selectedRow: Record<string, string>;
    selectedSegment;
}) {
    const selectedRowRef = useRef<HTMLTableRowElement>(null);
    const [sorting, setSorting] = useState<{
        column: string;
        type: SortingType;
    }>({
        column: 'Index',
        type: 'asc',
    });

    const { headers, jsx: headerJSX } = useMemo(
        () =>
            segmentTable?.data?.[0]?.reduce(
                (acc, cell, x) => ({
                    headers: [...acc.headers, cell.name],
                    jsx: [
                        ...acc.jsx,
                        <StyledTable.Th
                            key={`body-row-${cell.name}`}
                            className={classNames({
                                'w-[400px]': x === 0,
                                'w-[100px]': x > 0,
                            })}
                        >
                            {cell.name === 'Index' ? (
                                headerIndexMap[selectedTab]
                            ) : (
                                <TableHeaderCell
                                    cell={cell.name}
                                    onClick={() => {
                                        setSorting({
                                            column: cell.name,
                                            type: handleCellClickType(
                                                (cell.name === sorting.column &&
                                                    sorting.type) as SortingType,
                                            ),
                                        });
                                    }}
                                    sorting={sorting}
                                >
                                    <StyledTooltip
                                        label={driverBreakdown[selectedSegment]?.[cell?.name] || ''}
                                    >
                                        <span>{cell.name}</span>
                                    </StyledTooltip>
                                </TableHeaderCell>
                            )}
                        </StyledTable.Th>,
                    ],
                }),
                { jsx: [], headers: [] },
            ) ?? { headers: [], jsx: [] },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [segmentTable?.data, sorting],
    );

    const { titles, gettingRepeatedColumns: columnsSpan } = useMemo(() => {
        const gettingRepeatedColumns: Record<string, any[]> = {};
        const objectifiedArrays =
            segmentTable?.data?.reduce(
                (acc: Record<string, string>[], item, i) => [
                    ...acc,
                    item.reduce((subACC: Record<string, string>, subItem) => {
                        const key = getColumnSpan(subItem);
                        gettingRepeatedColumns[key] = [
                            ...(gettingRepeatedColumns?.[key] ?? []),
                            subItem,
                        ];
                        return {
                            ...subACC,
                            [subItem.name]: subItem.title,
                            [getSub(subItem.name)]: subItem.subtitle,
                        };
                    }, {}),
                ],
                [],
            ) ?? [];

        return {
            titles: objectifiedArrays,
            gettingRepeatedColumns,
        };
    }, [segmentTable?.data]);

    const rowItems = (item, i) =>
        headers.map((name, x) => {
            const title = item[name];
            const subtitle = item[getSub(name)]?.trim();
            const span =
                columnsSpan[
                    getColumnSpan({ name, title: item[name], subtitle: item[getSub(name)] })
                ];
            if (span.length === titles.length && i > 0) return null;

            return (
                <StyledTable.Td
                    key={`body-row-${name}-${i * 1}`}
                    className={classNames('text-start !min-h-20', {
                        'max-w-min': x > 0,
                        '!max-w-[400px]': x === 0,
                        blur: name === 'Sales Impact',
                    })}
                    rowSpan={span.length === titles.length ? titles.length : undefined}
                >
                    <div
                        className={classNames('relative flex flex-col', {
                            'size-min':
                                selectedRow &&
                                item.Index === selectedRow[selectedTab] &&
                                selectedRow.column === name,
                        })}
                    >
                        {selectedRow &&
                            item.Index === selectedRow[selectedTab] &&
                            selectedRow.column === name && (
                                <div
                                    className={classNames(
                                        'absolute inline-flex animate-[ping_2s_cubic-bezier(0,_0,_0.2,_1)_2] rounded-full opacity-75',
                                        {
                                            'size-full': x > 0,
                                            '!max-w-[440px] size-full': x === 0,
                                        },
                                    )}
                                />
                            )}
                        <Text
                            type="p/m"
                            className={classNames({
                                'whitespace-nowrap': x > 0 && !title?.includes(', '),
                            })}
                        >
                            {title?.includes('฿') && '฿'}
                            {title?.includes('฿')
                                ? formatNumber(title?.replace(/฿/g, '')?.trim(), '0.0a')
                                : title?.replace(/-/g, '')}
                        </Text>
                        {Boolean(subtitle) &&
                            !subtitle.includes('0.0%') &&
                            subtitle.trim() !== '0' && (
                                <Text type="p/m">{modifyString(subtitle)}</Text>
                            )}
                    </div>
                </StyledTable.Td>
            );
        });

    const body = useMemo(
        () =>
            (sorting.type
                ? titles.toSorted((a, b) => {
                      if (!a?.[sorting.column]) return 0;

                      const aX = Number(
                          a[sorting.column].replace(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,<>/?~฿]/g, ''),
                      );
                      const bX = Number(
                          b[sorting.column].replace(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,<>/?~฿]/g, ''),
                      );
                      if (sorting.type === 'desc') return aX - bX;
                      if (sorting.type === 'asc') return bX - aX;
                      return 0;
                  })
                : titles
            ).map((item, i) => {
                const isSelectedRow = selectedRow && item.Index === selectedRow[selectedTab];
                return searchedIndex === '' ||
                    item.Index.toLowerCase().includes(searchedIndex.toLowerCase()) ? (
                    <StyledTable.Tr
                        key={`body-row-${i * 1}`}
                        ref={(isSelectedRow && selectedRowRef) || undefined}
                        className="h-16"
                    >
                        {rowItems(item, i)}
                    </StyledTable.Tr>
                ) : null;
            }) ?? [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [segmentTable?.data, sorting, searchedIndex],
    );

    useEffect(() => {
        if (body.length === 0 || !selectedRowRef.current) return;
        selectedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        selectedRowRef.current = null;
    }, [body.length]);

    return { headerJSX, body };
}

export default useSegmentsTable;
